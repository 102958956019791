import { FC } from 'react';
import { EXTERNAL_SSO_LINK } from 'common/constants';
import { ContentProps } from 'models/profile-settings.model';
import { DetailSection } from './detail-section.component';

interface ContactInfoProps {
  primaryPhoneNumber: string;
  email: string;
}

export const ContactInfoComponent: FC<ContactInfoProps> = ({
  primaryPhoneNumber,
  email,
}) => {
  const contents: ContentProps[] = [
    {
      labelId: 'STR_PRIMARY_PHONE_NUMBER',
      value: primaryPhoneNumber,
      type: 'phone',
    },
    { labelId: 'STR_EMAIL', value: email, type: 'email' },
  ];

  return (
    <DetailSection
      title="STR_CONTACT_INFORMATION"
      contents={contents}
      ssoUrl={EXTERNAL_SSO_LINK.PERSONAL_INFO}
    />
  );
};
