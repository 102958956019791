import { LEAGUE_MODULE_NAMES, getModulePath } from '@leagueplatform/core';
import { GDSIconProps } from '@leagueplatform/genesis-core';
import { APP_MANAGED_ROUTES } from 'common/constants';

export enum NavIds {
  HOME = 'STR_HOME',
  BENEFITS = 'STR_BENEFITS',
  CARE = 'STR_GET_CARE',
  CLAIMS = 'STR_CLAIMS',
  MESSAGES = 'STR_MESSAGES',
}

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

export type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLinkInterface extends NavItem {
  message_id: NavIds;
  icon?: GDSIconProps['icon'];
}

export const baseNavLinks: NavLinkInterface[] = [
  {
    to: APP_MANAGED_ROUTES.HOME,
    message_id: NavIds.HOME,
    icon: 'illustrativeHome',
  },
  {
    to: getModulePath(LEAGUE_MODULE_NAMES.benefits),
    message_id: NavIds.BENEFITS,
    icon: 'capabilityWallet',
  },
  {
    to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
    message_id: NavIds.CARE,
    icon: 'illustrativeHeart',
  },
  {
    to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
    message_id: NavIds.MESSAGES,
    icon: 'capabilityJourney',
  },
];

export const claimsNavLink: NavLinkInterface = {
  to: '/claims?h=1',
  message_id: NavIds.CLAIMS,
  icon: 'capabilityClaims',
};
