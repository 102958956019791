import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useNavBarLogic = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const history = useHistory();

  const toggleMenu = useCallback(() => setMenuOpen((prev) => !prev), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);
  const handleLogoClick = useCallback(() => history.push('/'), [history]);

  return {
    menuOpen,
    toggleMenu,
    closeMenu,
    handleLogoClick,
  };
};
