import { useMemo } from 'react';
import { useGetAppInitDetails } from './use-get-app-init-details';

const CSB_PRODUCTS = ['medsupp', 'dvh', 'shl']; // Customer Specific Benefits
const MEDICARE_PRODUCTS = ['ma', 'mapd', 'pdp']; // Medicare-regulated plans

interface SegmentationResult {
  isCSBSegment: boolean; // Pure MedSupp/DVH/SHL
  isMedicareSegment: boolean; // Pure MA/MAPD/PDP
  isMixedSegment: boolean; // MA/MAPD/PDP + CSB
  isAddressEditable: boolean;
  showPaperlessSetup: boolean;
}

export const useProfileSettingsSegmentation = (): SegmentationResult => {
  const { data: appInitDetails } = useGetAppInitDetails();

  const productSubTypes = useMemo(
    () => appInitDetails?.segmentation?.productSubTypes ?? [],
    [appInitDetails],
  );

  const segmentation = useMemo(() => {
    const normalizedProductSubTypes = productSubTypes.map((type) =>
      type.toLowerCase(),
    );

    const hasCSB = normalizedProductSubTypes.some((type) =>
      CSB_PRODUCTS.includes(type),
    );
    const hasMedicare = normalizedProductSubTypes.some((type) =>
      MEDICARE_PRODUCTS.includes(type),
    );

    const isCSBSegment = hasCSB && !hasMedicare; // Pure CSB
    const isMedicareSegment = hasMedicare && !hasCSB; // Pure Medicare
    const isMixedSegment = hasCSB && hasMedicare; // Mixed

    const isAddressEditable = hasCSB; // Editable if any CSB product exists

    const showPaperlessSetup = hasMedicare; // Shown if any Medicare product exists

    return {
      isCSBSegment,
      isMedicareSegment,
      isMixedSegment,
      isAddressEditable,
      showPaperlessSetup,
    };
  }, [productSubTypes]);

  return segmentation;
};
