import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { StackLayout } from '@leagueplatform/genesis-core';
import { ContentSection } from 'components/content-section/content-section.component';
import { useHistory } from 'react-router-dom';

const LearnAboutAuthorizationPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  useDocumentTitle(formatMessage({ id: 'STR_LEARN_ABOUT_AUTHORIZATION' }));

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'STR_LEARN_ABOUT_AUTHORIZATION' })}
        goBack={history.goBack}
      />
      <StackLayout
        css={{
          maxWidth: 784,
        }}
      >
        <StackLayout
          css={{
            paddingTop: '$three',
            paddingBottom: '$five',
          }}
        >
          <ContentSection
            titleId="STR_AUTHORIZATION_TITLE"
            contentId="STR_AUTHORIZATION_DESC"
          />
          <ContentSection
            titleId="STR_HOW_TO_GET_AUTHORIZATION_TITLE"
            contentId="STR_HOW_TO_GET_AUTHORIZATION_DESC"
          />
          <ContentSection
            titleId="STR_SERVICES_REQUIRE_AUTHORIZATION_TITLE"
            contentId="STR_SERVICES_REQUIRE_AUTHORIZATION_DESC"
          />
          <ContentSection
            titleId="STR_IF_AUTHORIZATION_IS_NOT_RECEIVED_TITLE"
            contentId="STR_IF_AUTHORIZATION_IS_NOT_RECEIVED_DESC"
          />
        </StackLayout>
      </StackLayout>
    </MainPageContainer>
  );
};

export default LearnAboutAuthorizationPage;
