import { useMemo } from 'react';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { useQueryClient, useMutation } from 'react-query';
import snakecaseKeys from 'snakecase-keys';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  setUserProfile,
  UserProfile,
} from '@leagueplatform/user-profile-api';
import { usePreferredFirstName } from './use-preferred-first-name.hook';

interface Arguments {
  onSuccess?: () => void;
  onError?: () => void;
}

export const usePersonalInformation = () => {
  const { data, isLoading, error } = useGetUserProfile();
  const {
    data: firstNameData,
    isLoading: firstNameIsLoading,
    error: firstNameError,
  } = usePreferredFirstName();

  const homeAddress = useMemo(
    () => data?.userProfile?.locations?.find((loc) => loc.type === 'home'),
    [data?.userProfile?.locations],
  );

  const mailingAddress = useMemo(
    () => data?.userProfile?.locations?.find((loc) => loc.type === 'mail'),
    [data?.userProfile?.locations],
  );

  const fullName = useMemo(
    () =>
      `${firstNameData?.preferredFirstName?.toUpperCase() ?? ''} ${
        data?.userProfile.lastName ?? ''
      }`,
    [firstNameData?.preferredFirstName, data?.userProfile.lastName],
  );

  const userInitials = useMemo(
    () =>
      `${firstNameData?.preferredFirstName?.[0] ?? ''}${
        data?.userProfile?.lastName?.[0] ?? ''
      }`,
    [firstNameData?.preferredFirstName, data?.userProfile.lastName],
  );

  const customFields: Array<{ name: string; value: boolean | string }> =
    useMemo(() => (data as any)?.customFields || [], [data]);

  const showClaims = useMemo(
    () =>
      customFields.find((field) => field.name === 'show_claims_navigation')
        ?.value === 'true',
    [customFields],
  );

  const paperlessOptIn = useMemo(
    () =>
      customFields.find((field) => field.name === 'paperlessOptIn')?.value ===
        true ||
      customFields.find((field) => field.name === 'paperlessOptIn')?.value ===
        'true',
    [customFields],
  );

  const personalizedEmailOptIn = useMemo(
    () =>
      customFields.find((field) => field.name === 'personalizedEmailOptIn')
        ?.value === true ||
      customFields.find((field) => field.name === 'personalizedEmailOptIn')
        ?.value === 'true',
    [customFields],
  );

  const tipsAndRecOptIn = useMemo(
    () =>
      customFields.find((field) => field.name === 'tipsAndRecOptIn')?.value ===
        true ||
      customFields.find((field) => field.name === 'tipsAndRecOptIn')?.value ===
        'true',
    [customFields],
  );

  const productUpdatesOptIn = useMemo(
    () =>
      customFields.find((field) => field.name === 'productUpdatesOptIn')
        ?.value === true ||
      customFields.find((field) => field.name === 'productUpdatesOptIn')
        ?.value === 'true',
    [customFields],
  );

  const textMessageOptIn = useMemo(
    () =>
      customFields.find((field) => field.name === 'textMessageOptIn')?.value ===
        true ||
      customFields.find((field) => field.name === 'textMessageOptIn')?.value ===
        'true',
    [customFields],
  );

  const languageOptIn = useMemo(
    () =>
      customFields.find((field) => field.name === 'languageOptIn')?.value ===
        true ||
      customFields.find((field) => field.name === 'languageOptIn')?.value ===
        'true',
    [customFields],
  );

  const onboardingDeeplink = useMemo(() => {
    const deeplinkValue = customFields.find(
      (field) => field.name === 'onboarding_deeplink',
    )?.value;
    return typeof deeplinkValue === 'string' ? deeplinkValue : '/home';
  }, [customFields]);

  return {
    data: {
      ...data,
      fullName,
      userInitials,
      customFields,
      homeAddress,
      mailingAddress,
      paperlessOptIn,
      personalizedEmailOptIn,
      tipsAndRecOptIn,
      productUpdatesOptIn,
      textMessageOptIn,
      languageOptIn,
      onboardingDeeplink,
    },
    showClaims,
    isLoading: isLoading || firstNameIsLoading,
    error: error ?? firstNameError,
  };
};

/**
 * @name useUpdateUserProfile
 * @desc React hook to update the user profile
 * @returns Function that updates user profile
 */
export const useUpdateUserProfile = ({ onSuccess, onError }: Arguments) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateUserProfileMutation, isLoading } = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]);
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    },
  );

  const updateUserProfile = async (userProfile: any) => {
    const serializedBody = snakecaseKeys(userProfile);
    await updateUserProfileMutation(serializedBody);
  };

  return { updateUserProfile, isLoading };
};
