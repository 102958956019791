import { FC, useCallback } from 'react';
import {
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface ReadOnlyDetailsSectionProps {
  title: string;
  description: string;
}

export const ReadOnlyDetailsSection: FC<ReadOnlyDetailsSectionProps> = ({
  title,
  description,
}) => {
  const { formatMessage } = useIntl();

  const t = useCallback(
    (id: string) => formatMessage({ id: id as never }),
    [formatMessage],
  );

  return (
    <StackLayout
      css={{
        width: '100%',
        margin: '$half auto',
        border: '1px solid $onSurfaceBorderSubdued',
        borderRadius: '$card',
        overflow: 'hidden',
        padding: '$two $oneAndHalf',
      }}
    >
      <StackLayout
        orientation="horizontal"
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '$half',
          width: '100%',
        }}
      >
        <HeadingText level="3" size="md" css={{ fontSize: '20px' }}>
          {t(title)}
        </HeadingText>
      </StackLayout>

      <ParagraphText css={{ typography: '$bodyTwo', marginBottom: '$one' }}>
        {t(description)}
      </ParagraphText>
    </StackLayout>
  );
};
