import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  HealthProfileRoutes,
  Home,
  OnboardingRoutesWithProvider,
  WalletRoutes,
  ClaimsRoutes,
} from 'components/LazyRoutes';

import ErrorPage from 'pages/Error404';
import AboutCignaHealthcare from 'pages/about-cigna/about-cigna.page';
import { useConfigWithFeatureFlags } from 'hooks/use-config-with-feature-flags';
import FormsCenterLandingPage from 'pages/forms-center/forms-center-landing-page.page';
import ProfileSettingsPage from 'pages/profile-settings/profile-settings.page';
import AuthorizationPage from 'pages/authorization/learn-about-authorization.page';
import { useInterceptLinkClick } from 'hooks/use-intercept-link-click.hook';
import { useDynamicConfig } from 'hooks/use-dynamic-config.hook';
import { useIsFormsCenterAvailable } from 'hooks/use-is-forms-center-available.hook';
import { useConditionalNavigation } from 'hooks/use-conditional-navigation.hook';
import { APP_MANAGED_ROUTES } from 'common/constants';
import { AuthSessionContainer } from './auth-session-container/auth-session-container';
import { OnboardingWrapper } from './onboarding/onboarding-wrapper';
import { BasePageProvider } from './base-page-provider/base-page-provider.component';
import { AuthSignIn } from './auth/auth-sign-in.component';
import { AuthSignOut } from './auth/auth-sign-out.component';
import { HomeRedirect } from './home-redirect';

function AppRoutes() {
  useDynamicConfig();
  useConfigWithFeatureFlags();
  useConditionalNavigation();
  useInterceptLinkClick('/sso/vendors/', import.meta.env.VITE_LINKOUT_ROOT_URL);
  const isFormCenterAvailable = useIsFormsCenterAvailable();

  return (
    <>
      {/* If the user directly goes directly to an SSO route we cannot handle the SSO flow because */}
      {/* A user interaction is required to prevent the ad-blocker from blocking the SSO new tab flow.   */}
      <Route path="/sso/vendors" component={HomeRedirect} />
      <Switch>
        <Route path={APP_MANAGED_ROUTES.SIGN_IN} component={AuthSignIn} />
        <Route path={APP_MANAGED_ROUTES.SIGN_OUT} component={AuthSignOut} />
        <Route path="/" exact component={LandingPage} />
        <AuthSessionContainer>
          <OnboardingWrapper>
            <BasePageProvider>
              <Switch>
                <Route
                  path={APP_MANAGED_ROUTES.HOME}
                  component={Home}
                  exact
                  key="home"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.healthJourney)}
                  component={HealthJourneyRoutes}
                  key="health-journey"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.healthPrograms)}
                  component={HealthJourneyRoutes}
                  key="health-journey"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory)}
                  component={HealthJourneyRoutes}
                  key="health-journey"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                  component={CareCollectionsRoutes}
                  key="care-collections"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                  component={CareDiscoveryRoutes}
                  key="care-discovery"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
                  component={HealthNeedPages}
                  key="health-needs"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                  component={WalletRoutes}
                  key="wallet"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                  component={HealthProfileRoutes}
                  key="health-profile"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
                  component={OnboardingRoutesWithProvider}
                  key="onboarding"
                />
                <CompatRoute
                  path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                  component={ClaimsRoutes}
                  key="claims"
                />
                <Route
                  path={APP_MANAGED_ROUTES.ABOUT_CIGNA_ROUTE}
                  component={AboutCignaHealthcare}
                  key="about"
                />
                <Route
                  path={APP_MANAGED_ROUTES.PROFILE_SETTINGS}
                  component={ProfileSettingsPage}
                  key="profile-settings"
                />
                <Route
                  path={APP_MANAGED_ROUTES.LEARN_ABOUT_AUTHORIZATION}
                  component={AuthorizationPage}
                  key="authorization"
                />
                {isFormCenterAvailable ? (
                  <Route
                    path={APP_MANAGED_ROUTES.FORMS_CENTER_ROUTE}
                    component={FormsCenterLandingPage}
                    key="forms-center"
                  />
                ) : null}
                <Route component={ErrorPage} />
              </Switch>
            </BasePageProvider>
          </OnboardingWrapper>
        </AuthSessionContainer>
      </Switch>
    </>
  );
}

export default AppRoutes;
