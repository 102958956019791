import { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { usePersonalInformation } from 'hooks/use-personal-information.hook';
import { CoverageExperience } from 'common/constants';
import { GetStarted } from './get-started-screen.component';

export const OnboardingWrapper = ({ children }: { children: JSX.Element }) => {
  const history = useHistory();
  const { data: userData, isLoading: isLoadingUserProfile } =
    usePersonalInformation();
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);

  const customFields = userData?.customFields || [];

  // Calculate days since the user joined; default to -1 if not available
  const daysSinceJoined = userData?.joinedTimestamp
    ? differenceInDays(Date.now(), userData.joinedTimestamp)
    : -1;

  // Get the coverage experience and onboarding deeplink from custom fields
  const coverageExperience =
    (customFields.find(
      (field: { name: string }) => field.name === 'coverage_experience',
    )?.value as CoverageExperience) || CoverageExperience.Active;

  // Determine if the onboarding flow should be skipped
  const shouldSkipFlow =
    daysSinceJoined > 0 ||
    coverageExperience === CoverageExperience.PostEffective;

  useEffect(() => {
    if (shouldSkipFlow) {
      setIsSetupComplete(true);
      setIsOnboardingComplete(true);
    }
  }, [shouldSkipFlow]);

  const navigateToDeeplink = () => {
    history.push(userData.onboardingDeeplink);
  };

  const handleOnboardingComplete = () => {
    setIsOnboardingComplete(true);
    navigateToDeeplink();
  };

  if (isLoadingUserProfile) {
    return <FullPageLoadingSpinner />;
  }

  // TODO: Replace with actual account setup component if needed
  if (!isSetupComplete) {
    return null;
  }

  if (!isOnboardingComplete) {
    return <GetStarted handleOnboardingCompleted={handleOnboardingComplete} />;
  }

  return children;
};
