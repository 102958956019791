import { memo } from 'react';
import { AppBarHeader } from '@leagueplatform/web-common-components';
import {
  useMediaQuery,
  styled,
  Box,
  queryHelpers,
} from '@leagueplatform/genesis-core';

import { logout } from 'utils/auth/logout';
import { AccountModals } from 'components/modals/account-modals.component';
import { LoadingSpinner } from 'components/loaders/loading-spinner.component';
import { usePersonalInformation } from 'hooks/use-personal-information.hook';
import { useNavBarLogic } from 'hooks/use-navbar-logic.hook';
import { useNavLinksList } from 'hooks/useNav-links.hook';
import cignaLogo from 'assets/cigna-logo.svg';
import { MainNav } from './main-nav.component';
import { MobileNavWrapper } from './mobile-nav/mobile-nav-wrapper.component';

const Logo = styled('img', {
  width: '74px',
  height: '40px',
  margin: 'auto 70px auto 32px',
});

const APP_NAV_DEFAULT_HEIGHT = 64;

export const AppNavBarComponent: React.FC = memo(() => {
  const { isLoading, showClaims } = usePersonalInformation();
  const { navLinks } = useNavLinksList(showClaims);
  const { menuOpen, toggleMenu, closeMenu, handleLogoClick } = useNavBarLogic();
  const isAboveLaptop = useMediaQuery(queryHelpers.up('laptop'));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handleLogout = () => logout(window.location.origin);

  return (
    <Box
      css={{
        '> header > nav > ul > li > a[aria-current="page"]::after': {
          backgroundColor: '$interactiveActionPrimary',
        },
      }}
    >
      <AppBarHeader navHeight={APP_NAV_DEFAULT_HEIGHT}>
        <Logo src={cignaLogo} alt="Cigna Logo" onClick={handleLogoClick} />
        {isAboveLaptop ? (
          <MainNav navLinks={navLinks} handleDeleteAccount={toggleMenu} />
        ) : (
          <MobileNavWrapper
            navLinks={navLinks}
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
            handleDeleteAccount={toggleMenu}
          />
        )}
      </AppBarHeader>
      <AccountModals onLogout={handleLogout} />
    </Box>
  );
});

AppNavBarComponent.displayName = 'AppNavBarComponent';
