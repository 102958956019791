export const FAQ = '';

export const APP_MANAGED_ROUTES = {
  FORMS_CENTER_ROUTE: `/forms-center`,
  ABOUT_CIGNA_ROUTE: `/about`,
  HOME: `/home`,
  SIGN_IN: `/sign-in`,
  SIGN_OUT: `/sign-out`,
  LEARN_ABOUT_AUTHORIZATION: '/learn-about-authorizations',
  PROFILE_SETTINGS: '/profile-settings',
};

export const CAMPAIGN_ID = '3YrleST5vSTDD2gd6Lb5Y3';
export const ACTIVITY_ID = '7AVbWDWZm8qosC59SvE2Hb';

export const CARE_ADVISOR_EMAIL = 'careadvisorconnect@optum.com';
export const CARE_ADVISOR_PHONE_NUMBER = '866-658-4644';
export const VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH =
  '/view-health-authorization';

export const NPSSurveyConfig = {
  interceptId: 'SI_2nKmq5YVHcToqma',
  brandId: 'uhg1',
  projectId: 'ZN_3WAcqHSsRfyx3b8',
};

export const MASONRY = {
  API_VERSION: 1,
  CUSTOM_WIDGET_TYPE_HEALTHLINK_LOGO: 'healthlink_logo',
  HEALTH_JOURNEY_MODULE_KEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  MESSAGING_MODULE_KEY: 'messaging',
  CUSTOM_WIDGET_TYPE_MESSAGING: 'recent_messages',
  MODULE_KEY_CLAIMS: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
  CUSTOM_WIDGET_TYPE_ACTIVITY: 'activity_stack',
};

export const MESSAGE_TYPES = {
  GET_APP_INIT_DETAILS: 'get_app_init_details',
  GET_WIDGET_CONTAINER: 'get_widget_container',
};

export const CALLBACK_STATUS = {
  QUEUED: 'QUEUED',
  COMPLETED: 'COMPLETED',
};

export const AUTH0_ERROR_TYPES = {
  LOGIN_REQUIRED: 'login_required',
};

/* eslint-disable no-irregular-whitespace */
export const ABOUT_US_DESCRIPTION_TEXT = `
<div>
    <p>A powerful, full-featured tool for managing all aspects of your health care on the go. Dynamic and colorful interface puts interactive functionality at your fingertips: swipe, tap, and spin to unlock all your app’s features and the details of your health care.</p>
    <h3>myCignaMedicare Features</h3>
    <p>​​Based on your health care plan, you may not have all the features described below.</p>
    <h6>ID Cards</h6>
    <ul>
        <li>Instantly view ID cards (front and back)</li>
        <li>Easily print, email, fax, or scan right from your mobile device</li>
    </ul>
    <h6>Claims & EOBs</h6>
    <ul>
        <li>View and filter recent and past claims</li>
        <li>Download EOBs</li>
    </ul>
    <h6>Account Balances</h6>
    <ul>
        <li>Review plan deductibles and coinsurance</li>
        <li>Submit request for reimbursement</li>
    </ul>
    <h6>Home Delivery Pharmacy</h6>
    <ul>
        <li>View and refill your prescriptions right from your mobile device</li>
        <li>Update billing and shipping references</li>
    </ul>
    <h6>Drug Search</h6>
    <ul>
        <li>Look up and compare actual costs at 60,000 pharmacies nationwide</li>
        <li>Find closest pharmacy location using GPS</li>
        <li>Speed-dial Cigna Home Delivery Pharmacy</li>
    </ul>
    <h6>Health Care Professional Directory</h6>
    <ul>
        <li>Search for a doctor, dentist, pharmacy, or health care facility from Cigna’s national network and compare quality-of-care ratings</li>
    </ul>
    <h6>Cigna Telehealth Connection</h6>
    <ul>
        <li>Connect with nationwide network of doctors via video or phone</li>
    </ul>
    <h6>Benefits & Coverage</h6>
    <ul>
        <li>View plan coverage and authorizations</li>
        <li>Find what is covered under your plan</li>
    </ul>
    <h6>Languages Supported</h6>
    <ul>
        <li>English</li>
    </ul>
    <h6>Safe & Secure</h6>
    <ul>
        <li>We use the latest technology in secure mobile applications to protect your personal health info while online or off.</li>
    </ul>
</div>
`;

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'cigna-web',
  NO_INFO_AVAILABLE: 'no info available',
};

export const AUTH_VALIDATION_ERRORS = {
  DELETED_USER: 'unable_to_login_deleted_user',
  INELIGIBLE_USER: 'unable_to_login_ingeligible_user',
};

export const EXTERNAL_LINK = {
  TERMS_OF_USE: 'https://www.cigna.com/legal/compliance/terms-of-use',
  LANGUAGE_ASSISTANCE:
    'https://www.cigna.com/legal/members/language-assistance',
  APPEALS: 'https://www.cigna.com/medicare/member-resources/appeals-exceptions',
  GRIEVANCES: 'https://www.cigna.com/medicare/member-resources/grievances',
  DISCLAIMERS: 'https://www.cigna.com/legal/compliance/',
  PRIVACY_POLICY: 'https://www.cigna.com/medicare/privacy',
  PRIVACY_FORMS:
    'https://www.cigna.com/medicare/member-resources/customer-forms',
  HIPPA_NOTICE_OF_PRIVACY_PRACTICES:
    'https://www.cigna.com/static/www-cigna-com/docs/cigna-health-care-and-cigna-supplemental-benefits-privacy-notice-eng.pdf',
  GRAMM_LEACH_BILLEY_ACT_NOTICE:
    'https://www.cigna.com/static/www-cigna-com/docs/gramm-leach-bliley-act-privacy-notice.pdf',
  NONDISCRIMINATION_NOTICE: 'https://www.cigna.com/medicare/nondiscrimination',
  MY_CIGNA: 'https://my.cigna.com/',
  PRIVACY_NOTICES: 'https://www.cigna.com/legal/compliance/privacy-notices',
  WASHINGTON_CONSUMER_PRIVACY_NOTICE:
    'https://www.cigna.com/legal/compliance/privacy-notices/consumer-health-data-privacy-notice-washington',
};

export const EXTERNAL_SSO_LINK = {
  PERSONAL_INFO: '/sso/vendors/mycigna?redirect_key=profile',
  MANAGE_PHARMACY_SETTINGS: '/sso/vendors/expressscripts?content_area=orderrx',
};

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export enum CoverageExperience {
  Active = 'active',
  PreEffective = 'pre-effective',
  PostEffective = 'post-effective',
}
