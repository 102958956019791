import { memo, useCallback, useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  Icon,
  Modal,
  Divider,
  Box,
} from '@leagueplatform/genesis-core';
import { NavLink } from 'react-router-dom';

import {
  MobileNavLink,
  defaultMobileNavLinkStyles,
} from 'components/header-nav/navbar/mobile-nav/mobile-nav.links.component';

interface MainNavLinkData {
  messageId: string;
  icon?: React.ReactNode;
  to: string;
  isActive: boolean;
}

interface DropdownItem {
  text: string;
  icon?: React.ReactNode;
  linkIcon?: React.ReactNode;
  onClick?: () => void;
}

interface DropdownGroup {
  heading?: string;
  items: DropdownItem[];
}

interface MobileNavProps {
  mainNavLinksData: MainNavLinkData[];
  dropdownItems: DropdownGroup[];
  onCapabilityNavLinkClick: (action: string) => void;
  onDropdownItemClick: (text: string, onClick?: () => void) => void;
}

export const MobileNav = memo(
  ({
    mainNavLinksData,
    dropdownItems,
    onCapabilityNavLinkClick,
    onDropdownItemClick,
  }: MobileNavProps) => {
    const { formatMessage } = useIntl();

    const mainNavLinks = useMemo(
      () =>
        mainNavLinksData.map(({ messageId, icon, to, isActive }) => {
          const handleClick = () => onCapabilityNavLinkClick(messageId);
          return (
            <Box
              as={NavLink}
              to={to}
              id={`${formatMessage({ id: messageId as never })}-link`}
              key={`${formatMessage({ id: messageId as never })}-link`}
              css={defaultMobileNavLinkStyles}
              onClick={handleClick}
            >
              {icon && (
                <Icon
                  icon={isActive ? `${icon}Filled` : icon}
                  css={{ marginRight: '$half' }}
                />
              )}
              {formatMessage({ id: messageId as never })}
              <Icon
                icon="interfaceChevronRight"
                size="$one"
                tint="$onSurfaceTextSubdued"
                css={{ marginLeft: 'auto' }}
              />
            </Box>
          );
        }),
      [mainNavLinksData, onCapabilityNavLinkClick, formatMessage],
    );

    const renderDropdownItem = useCallback(
      (item: DropdownItem) => {
        const handleClick = () => onDropdownItemClick(item.text, item.onClick);
        return (
          <MobileNavLink
            linkData={item}
            onLinkClick={handleClick}
            key={`mobile-nav-link--${item.text}`}
          >
            {item?.icon && (
              <Icon
                icon={item.icon}
                size="$oneAndHalf"
                css={{ marginRight: '$half' }}
              />
            )}
            {item.text}
            {item?.linkIcon && (
              <Icon
                icon={item.linkIcon}
                size="$one"
                tint="$onSurfaceTextSubdued"
                css={{ marginLeft: 'auto' }}
              />
            )}
          </MobileNavLink>
        );
      },
      [onDropdownItemClick],
    );

    return (
      <StackLayout as="nav" spacing="$one" horizontalAlignment="stretch">
        <Modal.Title>
          <HeadingText level="2" size="sm" css={{ paddingLeft: '$half' }}>
            {formatMessage({ id: 'STR_MENU' })}
          </HeadingText>
        </Modal.Title>
        <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
          {mainNavLinks}
        </StackLayout>
        {dropdownItems?.map(({ heading, items }) => (
          <StackLayout
            spacing="$oneAndHalf"
            css={{ width: '100%' }}
            key={`mobile_nav--${heading || items[0]?.text}`}
            horizontalAlignment="stretch"
          >
            <Divider css={{ margin: '0 $half', width: 'calc(100% - $half)' }} />
            <StackLayout spacing="$half" horizontalAlignment="stretch">
              {heading && (
                <HeadingText level="3" size="md" css={{ paddingLeft: '$half' }}>
                  {formatMessage({ id: heading as never })}
                </HeadingText>
              )}
              <StackLayout
                as="ul"
                spacing="$half"
                horizontalAlignment="stretch"
              >
                {items?.map(renderDropdownItem)}
              </StackLayout>
            </StackLayout>
          </StackLayout>
        ))}
      </StackLayout>
    );
  },
);

MobileNav.displayName = 'MobileNav';
