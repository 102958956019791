import { FC } from 'react';
import { Box, HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { AddressContent } from 'models/profile-settings.model';
import { EditableDetailItem } from '../editable-detail-item.component';

interface AddressEditSectionProps {
  address: AddressContent;
  editValues: Record<string, string>;
  onChange: (id: string, value: string) => void;
  isMobile: boolean;
}

export const AddressEditSection: FC<AddressEditSectionProps> = ({
  address,
  editValues,
  onChange,
  isMobile,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box css={{ width: '100%', marginBottom: '$two' }}>
      <HeadingText
        level="4"
        size="xs"
        css={{ fontSize: '18px', margin: '$one 0' }}
      >
        {formatMessage({ id: address.title as never })}
      </HeadingText>
      <StackLayout
        css={{
          display: 'grid',
          gridTemplateColumns: isMobile
            ? '1fr'
            : 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: isMobile ? '$two' : '$one',
          width: '100%',
        }}
      >
        {address.contents.map((content) => (
          <EditableDetailItem
            key={content.labelId}
            id={`${content.labelId}_${address.type}`}
            labelId={content.labelId}
            options={content.options}
            value={
              editValues[`${content.labelId}_${address.type}`] ?? content.value
            }
            type={content.type}
            onChange={onChange}
          />
        ))}
      </StackLayout>
    </Box>
  );
};
