import {
  StackLayout,
  Button,
  HeadingText,
  ParagraphText,
  Box,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import pageIcon from 'assets/onboarding-icon.svg';
import { usePersonalInformation } from 'hooks/use-personal-information.hook';
import { CoverageExperience } from 'common/constants';

interface GetStartedPageProps {
  handleOnboardingCompleted: () => void;
}

export const GetStarted = ({
  handleOnboardingCompleted,
}: GetStartedPageProps) => {
  const { formatMessage } = useIntl();
  const { data: userData } = usePersonalInformation();

  const coverageExperience =
    userData?.customFields?.find(
      (field: { name: string }) => field.name === 'coverage_experience',
    )?.value ?? CoverageExperience.Active;

  const descriptionMessageId =
    coverageExperience === CoverageExperience.PreEffective
      ? 'STR_GET_STARTED_DESC_PRE_EFFECTIVE'
      : 'STR_GET_STARTED_DESC_ACTIVE';

  return (
    <FullScreenContainer>
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{
          height: '100%',
          whiteSpace: 'pre-wrap',
          maxWidth: '351px',
          margin: '0 auto',
        }}
      >
        <Image
          src={pageIcon}
          alt={formatMessage({ id: 'STR_GET_STARTED_IMAGE_ALT' })}
          width="176px"
          height="auto"
          position="relative"
          top="minusOne"
          marginBottom="three"
        />
        <HeadingText level="3" size="xl" css={{ marginBottom: '$half' }}>
          {formatMessage({ id: 'STR_GET_STARTED' })}
        </HeadingText>
        <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
          {formatMessage({ id: descriptionMessageId })}
        </ParagraphText>
        <Box css={{ width: '180px', marginTop: '$twoAndHalf' }}>
          <Button width="fillContainer" onClick={handleOnboardingCompleted}>
            {formatMessage({ id: 'STR_LETS_GO' })}
          </Button>
        </Box>
      </StackLayout>
    </FullScreenContainer>
  );
};
