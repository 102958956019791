import React from 'react';
import {
  StackLayout,
  Button,
  ParagraphText,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import paperlessImg from 'assets/paperless.svg';
import { BaseModalContainer } from './common/base-modal.component';

interface ModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

const PaperlessConfirmationModal: React.FC<ModalProps> = ({
  onClose,
  onConfirm,
}) => {
  const { formatMessage } = useIntl();

  return (
    <BaseModalContainer
      title={formatMessage({ id: 'STR_CONFIRM_PAPERLESS_DELIVERY' })}
      footer={
        <StackLayout
          orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
          spacing="$oneAndHalf"
          verticalAlignment="stretch"
          horizontalAlignment="stretch"
          css={{
            '> *': {
              flex: '1',
              justifyContent: 'center',
            },
          }}
        >
          <Button
            css={{
              height: '$three',
              whiteSpace: 'nowrap',
            }}
            onClick={onClose}
            priority="secondary"
          >
            {formatMessage({ id: 'STR_CANCEL' })}
          </Button>
          <Button
            css={{
              height: '$three',
              whiteSpace: 'nowrap',
            }}
            onClick={onConfirm}
          >
            {formatMessage({ id: 'STR_GO_PAPERLESS' })}
          </Button>
        </StackLayout>
      }
    >
      <ImageMessage image={paperlessImg}>
        <ParagraphText>
          {formatMessage({ id: 'STR_PAPERLESS_MODAL_DESCRIPTION_1' })}
        </ParagraphText>
        <ParagraphText>
          {formatMessage({ id: 'STR_PAPERLESS_MODAL_DESCRIPTION_2' })}
        </ParagraphText>
        <ParagraphText>
          {formatMessage({ id: 'STR_PAPERLESS_MODAL_DESCRIPTION_3' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};

export default PaperlessConfirmationModal;
