import React from 'react';
import { Modal } from '@leagueplatform/genesis-core';

import { useModal } from 'hooks/use-modal.hook';
import { DeleteAccountModal } from './delete-account-modal.component';
import { AccountDeletedModal } from './account-deleted-modal.component';

interface AccountModalsProps {
  onLogout: () => void;
}

export const AccountModals: React.FC<AccountModalsProps> = ({ onLogout }) => {
  const {
    isShowing: showDeleteAccountModal,
    toggle: toggleDeleteAccountModal,
  } = useModal();
  const {
    isShowing: showAccountDeletedModal,
    toggle: toggleAccountDeletedModal,
  } = useModal();

  return (
    <>
      <Modal.Root
        open={showDeleteAccountModal}
        onOpenChange={toggleDeleteAccountModal}
      >
        <DeleteAccountModal onDeleted={toggleAccountDeletedModal} />
      </Modal.Root>
      <Modal.Root
        open={showAccountDeletedModal}
        onOpenChange={() => {
          toggleAccountDeletedModal();
          onLogout();
        }}
      >
        <AccountDeletedModal />
      </Modal.Root>
    </>
  );
};

AccountModals.displayName = 'AccountModals';
