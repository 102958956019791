import { FC } from 'react';
import { Modal } from '@leagueplatform/genesis-core';
import PaperlessConfirmationModal from 'components/modals/paperless-confirmation-modal.component';
import { useCommunicationPreference } from 'hooks/use-communication-preference.hook';
import { useProfileSettingsSegmentation } from 'hooks/use-profile-settings-segmentation.hook';
import { CommunicationOption } from 'models/profile-settings.model';
import { UnsubscribeWarningModal } from 'components/modals/unsubscribe-warning-modal';
import { CommunicationEditForm } from './communication-edit-form.component';
import { DetailSection } from '../detail-section.component';

interface CommunicationComponentProps {
  title: string;
  paperlessOptIn: boolean;
  personalizedEmailOptIn: boolean;
  tipsAndRecOptIn: boolean;
  productUpdatesOptIn: boolean;
  textMessageOptIn: boolean;
  languageOptIn: boolean;
}

export const CommunicationComponent: FC<CommunicationComponentProps> = ({
  title,
  paperlessOptIn,
  personalizedEmailOptIn,
  tipsAndRecOptIn,
  productUpdatesOptIn,
  textMessageOptIn,
  languageOptIn,
}) => {
  const { showPaperlessSetup } = useProfileSettingsSegmentation();

  const initialOptions: CommunicationOption[] = [
    ...(showPaperlessSetup
      ? [{ labelId: 'STR_PAPERLESS', value: paperlessOptIn, type: 'radio' }]
      : []),
    {
      labelId: 'STR_PERSONALIZED_EMAIL',
      value: personalizedEmailOptIn,
      type: 'checkbox',
    },
    {
      labelId: 'STR_TIPS_AND_RECOMMENDATIONS',
      value: tipsAndRecOptIn,
      type: 'checkbox',
    },
    {
      labelId: 'STR_PRODUCT_UPDATES',
      value: productUpdatesOptIn,
      type: 'checkbox',
    },
    { labelId: 'STR_TEXT', value: textMessageOptIn, type: 'checkbox' },
    { labelId: 'STR_LANGUAGE', value: languageOptIn, type: 'checkbox' },
  ];

  const {
    contents,
    handleSave,
    handleInputChange,
    showPaperlessModal,
    setShowPaperlessModal,
    showUnsubscribeModal,
    setShowUnsubscribeModal,
    handlePaperlessConfirm,
    handlePaperlessCancel,
    handleUnsubscribeConfirm,
    handleUnsubscribeCancel,
    currentOptions,
  } = useCommunicationPreference(initialOptions);

  const renderEditContent = ({
    onChange,
  }: {
    editValues: Record<string, string>;
    onChange: (id: string, value: string) => void;
  }) => {
    const booleanValues = Object.fromEntries(
      currentOptions.map((opt) => [opt.labelId, opt.value]),
    );

    return (
      <CommunicationEditForm
        showPaperlessSetup={showPaperlessSetup}
        editValues={booleanValues}
        onInputChange={(id: string, value: boolean) => {
          handleInputChange(id, value);
          if (id !== 'STR_PAPERLESS' && id !== 'STR_PERSONALIZED_EMAIL') {
            onChange(id, value.toString());
          }
        }}
      />
    );
  };

  return (
    <DetailSection
      title={title}
      contents={contents}
      onSave={handleSave}
      renderEditContent={renderEditContent}
      modals={
        showPaperlessSetup ? (
          <>
            <Modal.Root
              open={showPaperlessModal}
              onOpenChange={() => setShowPaperlessModal(false)}
            >
              <PaperlessConfirmationModal
                onConfirm={handlePaperlessConfirm}
                onClose={handlePaperlessCancel}
              />
            </Modal.Root>
            <Modal.Root
              open={showUnsubscribeModal}
              onOpenChange={() => setShowUnsubscribeModal(false)}
            >
              <UnsubscribeWarningModal
                onConfirm={handleUnsubscribeConfirm}
                onClose={handleUnsubscribeCancel}
              />
            </Modal.Root>
          </>
        ) : null
      }
    />
  );
};
