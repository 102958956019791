import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLinkInterface } from 'models/nav-config.model';
import { sendCapabilityNavLinkEvent } from 'utils/analytics-events';

export const useMobileNavLogic = (
  navLinks: NavLinkInterface[],
  closeModal: () => void,
) => {
  const location = useLocation();

  const handleCapabilityNavLinkClick = useCallback(
    (action: string) => {
      closeModal();
      sendCapabilityNavLinkEvent(action);
    },
    [closeModal],
  );

  const mainNavLinksData = useMemo(
    () =>
      navLinks.map(({ message_id, icon, to }) => ({
        messageId: message_id,
        icon,
        to: to as string,
        isActive: location.pathname.startsWith(to as string),
      })),
    [navLinks, location.pathname],
  );

  return {
    mainNavLinksData,
    handleCapabilityNavLinkClick,
  };
};
