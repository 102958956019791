import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  FullPageError,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import {
  MasonryDriverNodeRenderersRegistry,
  MasonryWSDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import League, { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ActivityStackWidgetV2 } from '@leagueplatform/health-journey';
import { RecentClaimsCardList } from '@leagueplatform/claims';
import { config } from 'init-config';
import { ErrorBoundaryWrapper } from 'components/error/error-boundary.component';
import { FormCenterCard } from 'components/form-center/form-center-card.component';
import { APP_MANAGED_ROUTES } from 'common/constants';
import AppRoutes from './components/AppRoutes';

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
  [LEAGUE_MODULE_NAMES.healthJourney]: '/messages',
});

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'cigna',
  'cigna_form_center_item',
  FormCenterCard,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'health_journey',
  'activity_stack',
  ActivityStackWidgetV2,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'claims',
  'recent_claims_cards',
  RecentClaimsCardList,
);

export function App() {
  return (
    <BrowserRouter>
      <CompatRouter>
        <League.LeagueProvider config={config} treatConfigAsInitialOnly>
          <ErrorBoundaryWrapper>
            <Suspense fallback={<LoadingIndicator />}>
              {useOnlineStatus().isOffline && (
                <FullPageError
                  route={APP_MANAGED_ROUTES.HOME}
                  error={undefined}
                />
              )}
              {useOnlineStatus().isOnline && (
                <ErrorBoundaryWrapper>
                  <AppRoutes />
                </ErrorBoundaryWrapper>
              )}
              <ReactQueryDevtools />
            </Suspense>
          </ErrorBoundaryWrapper>
        </League.LeagueProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}
