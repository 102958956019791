import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  AppBarItem,
  AppBarNav,
  AppBarNavLink,
  AppBarNavList,
} from '@leagueplatform/web-common-components';
import { NavLinkInterface } from 'models/nav-config.model';
import { sendCapabilityNavLinkEvent } from 'utils/analytics-events';
import { UserDropdown } from './user-dropdown/user-dropdown.component';

interface MainNavProps {
  navLinks: NavLinkInterface[];
  handleDeleteAccount: () => void;
}

export const MainNav: React.FC<MainNavProps> = ({
  navLinks,
  handleDeleteAccount,
}) => {
  const { formatMessage } = useIntl();

  return (
    <AppBarNav>
      <AppBarNavList>
        {navLinks.map((navLink) => (
          <AppBarNavLink
            to={navLink.to}
            key={navLink.message_id}
            onClick={sendCapabilityNavLinkEvent(navLink.message_id)}
            color="onSurface.text.primary"
            fontWeight="bold"
            hoverStyle={{ color: 'interactive.action.primary' }}
            decorativeBarColor="interactive.action.primary"
            listItemProps={{
              flexGrow: 0,
              marginRight: 'twoAndHalf',
            }}
          >
            {formatMessage({ id: navLink.message_id })}
          </AppBarNavLink>
        ))}
        <AppBarItem justifyContent="flex-end" marginRight="five">
          <UserDropdown handleDeleteAccount={handleDeleteAccount} />
        </AppBarItem>
      </AppBarNavList>
    </AppBarNav>
  );
};

MainNav.displayName = 'MainNav';
