import { ErrorState } from '@leagueplatform/web-common-components';
import { Box } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

function ErrorPage() {
  const { formatMessage } = useIntl();

  return (
    <Box
      css={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ErrorState
        headingString={formatMessage({ id: 'STR_SORRY_WE_CANT_FIND_PAGE' })}
        error={undefined}
      />
    </Box>
  );
}

export default ErrorPage;
