import * as React from 'react';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { APP_NAV_DEFAULT_HEIGHT } from './header-nav/navbar/nav.constants';
import { AppNavBarComponent } from './header-nav/navbar/nav.component';
import { FooterComponent } from './footer/footer.component';

interface Props {
  isPrivatePage?: boolean;
  children: React.ReactNode;
  css?: GDSStyleObject;
}
// TODO: investigate if this style is still relevant

// const globalStyles = globalCss({
//   label: {
//     '&:focus': {
//       outline: 'none !important',
//       boxShadow: 'none !important',
//     },
//     '&:focus-within': {
//       outline: 'none !important',
//       boxShadow: 'none !important',
//     },
//   },
//   '#home-page .masonry-page-header-background': {
//     height: '50px !important',
//     '@media screen and (max-width: 1024px)': {
//       height: '20px !important',
//     },
//   },
//   a: {
//     maxWidth: 'none !important',
//   },
//   'img[alt="phone with four finger holding it"]': {
//     objectFit: 'contain',
//   },
//   '.GDS-elevated-card': {
//     boxShadow: '2px 2px 7px 0px #00000017 !important',
//     '& .GDS-card-click-area': {
//       boxShadow: 'none !important',
//       border: 'none !important',
//     },
//   },
// });

export const BasePage = ({
  children,
  isPrivatePage = true,
  css,
  ...boxProps
}: Props) => {
  useScrollTopOnLocationChange();

  // globalStyles();
  return (
    <>
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{
          overflowX: 'hidden',
          position: 'relative',
          flex: '1',
          minHeight: `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`,
          ...css,
        }}
        {...boxProps} // eslint-disable-line
      >
        {children}
      </Box>
      {isPrivatePage && <FooterComponent />}
    </>
  );
};
