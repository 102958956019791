import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { Location } from 'history';
import { useAuth } from '@leagueplatform/auth-utils';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { useIntl } from '@leagueplatform/locales';
import { captureAuthenticationError } from 'components/error/auth-error.component';
import { FullPageError } from 'components/error/full-page-error.component';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { APP_MANAGED_ROUTES, EXTERNAL_LINK } from 'common/constants';

const AUTH0_CLIENT_CONNECTION = 'cigna';

// Gets auth0 error returned from redirect, the returned error could be a query param or a hash param
const getAuth0ErrorIfExists = (location: Location) => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace(/#/g, '?'));

  const type = searchParams.get('error') || hashParams.get('error');
  const description =
    searchParams.get('error_description') ||
    hashParams.get('error_description');

  if (type) {
    return { type, description };
  }

  return null;
};

export const AuthSignIn = () => {
  const location = useLocation<{ redirectPath?: string } | undefined>();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const [error, setError] = useState('');

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      history.push({
        pathname: APP_MANAGED_ROUTES.HOME,
      });
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated === false) {
      const connection =
        new URLSearchParams(location.search).get('connection') ||
        AUTH0_CLIENT_CONNECTION;

      const authError = getAuth0ErrorIfExists(location);
      if (authError) {
        setError(`${authError.type}: ${authError.description || ''}`);
        captureAuthenticationError(authError);
        return;
      }

      try {
        StandaloneAuth.client.loginWithRedirect({
          connection,
          appState: {
            redirectPath: location.state?.redirectPath,
          },
        });
      } catch (e: unknown) {
        setError(e instanceof Error ? e.message : String(e));
      }
    }
  }, [location, history, isAuthenticated]);

  if (error) {
    return (
      <FullPageError
        errorType={ErrorTypes.API_ERROR}
        buttonLink={EXTERNAL_LINK.MY_CIGNA}
        buttonChildren={formatMessage({ id: 'STR_GO_TO_MY_CIGNA' })}
      />
    );
  }
  return null;
};
