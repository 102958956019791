import React, { useState, useEffect, FC } from 'react';
import {
  FormField,
  TextInput,
  Select,
  useMediaQuery,
  queryHelpers,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { formatPhoneNumber } from 'utils/format-phone-number';

interface EditableDetailItemProps {
  id: string;
  labelId: string;
  value: string;
  type: 'text' | 'phone' | 'email' | 'select';
  options?: Array<{ label: string; value: string }>;
  onChange: (id: string, value: string) => void;
  isDisabled?: boolean;
}

export const EditableDetailItem: FC<EditableDetailItemProps> = ({
  id,
  labelId,
  value,
  type,
  options,
  onChange,
  isDisabled = false,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const validateInput = (val: string) => {
    if (isDisabled) return true;
    let errorMessage = '';
    if (type === 'email') {
      errorMessage = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)
        ? ''
        : formatMessage({ id: 'STR_INVALID_EMAIL' });
    } else if (type === 'phone') {
      errorMessage =
        val.replace(/\D/g, '').length === 10
          ? ''
          : formatMessage({ id: 'STR_INVALID_PHONE' });
    }
    setError(errorMessage);
    return errorMessage === '';
  };

  const handleBlur = () => {
    if (isDisabled) return;
    validateInput(inputValue);
    if (error === '') {
      onChange(id, inputValue);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (isDisabled) return;
    let newValue = event.target.value;
    if (type === 'phone') {
      newValue = formatPhoneNumber(newValue);
    }
    setInputValue(newValue);
    validateInput(newValue);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (isDisabled) return;
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange(id, newValue);
  };

  return (
    <FormField
      id={id}
      label={formatMessage({ id: labelId as never })}
      css={{
        marginBottom: '$one',
        maxWidth: isMobile ? '292px' : '100%',
      }}
      inputStatus={error ? 'error' : undefined}
    >
      {type === 'select' ? (
        <Select
          options={options || []}
          value={inputValue}
          onChange={handleSelectChange}
          onBlur={handleBlur}
          disabled={isDisabled}
          css={{
            width: '100%',
            ...(isDisabled && {
              opacity: 0.6,
              backgroundColor: '$inputBackgroundDisabled',
            }),
          }}
        />
      ) : (
        <TextInput
          type={type === 'phone' ? 'tel' : type}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          disabled={isDisabled}
          css={{
            width: '100%',
            ...(isDisabled && {
              opacity: 0.6,
              backgroundColor: '$inputBackgroundDisabled',
            }),
          }}
        />
      )}
      {error && (
        <UtilityText
          size="sm"
          css={{ color: '$criticalIcon', marginTop: '$half' }}
        >
          {error}
        </UtilityText>
      )}
    </FormField>
  );
};
