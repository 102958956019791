import { AddressContent, ContentProps } from 'models/profile-settings.model';

export const formatAddress = (contents: ContentProps[]): string =>
  contents
    .map((content) => content.value)
    .filter(Boolean)
    .join(', ');

export const flattenAddressContents = (
  addresses: AddressContent[],
): ContentProps[] =>
  addresses.map((address) => ({
    labelId: address.title,
    value: formatAddress(address.contents),
    type: 'text',
  }));

export const getInitialEditValues = (
  addresses: AddressContent[],
): Record<string, string> =>
  Object.fromEntries(
    addresses.flatMap((address) =>
      address.contents.map((item) => [
        `${address.type}:${item.labelId}`,
        item.value,
      ]),
    ),
  );

export const transformEditValues = (
  editValues: Record<string, string>,
): Record<'home' | 'mailing', Record<string, string>> => {
  const result: Record<'home' | 'mailing', Record<string, string>> = {
    home: {},
    mailing: {},
  };
  Object.entries(editValues).forEach(([key, value]) => {
    const [type, labelId] = key.split(':') as ['home' | 'mailing', string];
    result[type][labelId] = value;
  });
  return result;
};
