import React, { useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Button, Modal } from '@leagueplatform/genesis-core';
import { NavLinkInterface } from 'models/nav-config.model';
import { useMobileNavLogic } from 'hooks/use-mobile-nav-logic.hook';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';
import { sendUserOptionEvent } from 'utils/analytics-events';
import { MobileNav } from './mobile-nav.component';

interface MobileNavWrapperProps {
  navLinks: NavLinkInterface[];
  menuOpen: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
  handleDeleteAccount: () => void;
}

export const MobileNavWrapper = React.memo(
  ({
    navLinks,
    menuOpen,
    toggleMenu,
    closeMenu,
    handleDeleteAccount,
  }: MobileNavWrapperProps) => {
    const { formatMessage } = useIntl();
    const { mainNavLinksData, handleCapabilityNavLinkClick } =
      useMobileNavLogic(navLinks, closeMenu);
    const dropdownItems = useUserDropdownConfig(handleDeleteAccount);

    const handleDropdownItemClick = useCallback(
      (text: string, onClick?: () => void) => {
        closeMenu();
        sendUserOptionEvent(text);
        if (onClick) onClick();
      },
      [closeMenu],
    );

    return (
      <Modal.Root open={menuOpen} onOpenChange={toggleMenu}>
        <Modal.Trigger>
          <Button
            icon="interfaceMenu"
            hideLabel
            quiet
            priority="tertiary"
            size="large"
            css={{ margin: '$half $quarter' }}
            aria-controls={undefined}
          >
            {formatMessage({ id: 'STR_OPEN_MENU' })}
          </Button>
        </Modal.Trigger>
        <Modal.Content
          layout="right"
          css={{
            '.GDS-modal': { maxWidth: 335 },
            '.GDS-modal-content': { paddingLeft: '$one' },
          }}
        >
          <MobileNav
            mainNavLinksData={mainNavLinksData}
            dropdownItems={dropdownItems}
            onCapabilityNavLinkClick={handleCapabilityNavLinkClick}
            onDropdownItemClick={handleDropdownItemClick}
          />
        </Modal.Content>
      </Modal.Root>
    );
  },
);

MobileNavWrapper.displayName = 'MobileNavWrapper';
