import { useHistory } from 'react-router-dom';
import { APP_MANAGED_ROUTES } from 'common/constants';
import { FullPageLoadingSpinner } from './loaders/full-page-loading-spinner.component';

export function HomeRedirect() {
  const history = useHistory();

  history.replace(APP_MANAGED_ROUTES.HOME);

  return <FullPageLoadingSpinner />;
}
