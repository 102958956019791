import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from 'react-router-dom';
import { usePersonalInformation } from 'hooks/use-personal-information.hook';
import { AddressInformationComponent } from 'components/profile-settings/address-information/address-information.component';
import { CommunicationComponent } from 'components/profile-settings/communication-preference/communication.component';
import { PersonalInfoComponent } from 'components/profile-settings/personal-Info.component';
import { ContactInfoComponent } from 'components/profile-settings/contact-info.component';

const ProfileSettingsPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  useDocumentTitle(formatMessage({ id: 'STR_PROFILE_SETTINGS' }));

  const { data } = usePersonalInformation();

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'STR_PROFILE_SETTINGS' })}
        goBack={history.goBack}
      />
      <StackLayout>
        <StackLayout
          css={{
            maxWidth: 952,
            marginY: '$four',
            '@desktop': {
              padding: '40px 32px',
              boxShadow: ' 0px 2px 6px 0px #00000014',
            },
          }}
        >
          <PersonalInfoComponent
            firstName={data?.userProfile?.firstName || ''}
            lastName={data?.userProfile?.lastName || ''}
            preferredFirstName={data?.userProfile?.preferredFirstName ?? ''}
          />
          <AddressInformationComponent
            mailingAddress={data?.mailingAddress ?? {}}
            residentialAddress={data?.homeAddress ?? {}}
          />
          <ContactInfoComponent
            primaryPhoneNumber={data?.phoneNumber || ''}
            email={data?.email || ''}
          />

          <CommunicationComponent
            title="STR_COMMUNICATIONS"
            paperlessOptIn={data.paperlessOptIn}
            personalizedEmailOptIn={data.personalizedEmailOptIn}
            tipsAndRecOptIn={data.tipsAndRecOptIn}
            productUpdatesOptIn={data.productUpdatesOptIn}
            textMessageOptIn={data.textMessageOptIn}
            languageOptIn={data.languageOptIn}
          />
        </StackLayout>
      </StackLayout>
    </MainPageContainer>
  );
};

export default ProfileSettingsPage;
