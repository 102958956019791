import { FC } from 'react';
import { ParagraphText, StackLayout } from '@leagueplatform/genesis-core';

interface DetailItemProps {
  label: string;
  value: string;
  isLastItem?: boolean;
}

export const DetailItem: FC<DetailItemProps> = ({
  label,
  value,
  isLastItem,
}) => (
  <StackLayout
    css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '12px 0',
      width: '100%',
      borderBottom: isLastItem ? 'none' : '1px solid #D6D6D6',
      '@mobile': {
        flexDirection: 'column',
      },
    }}
  >
    <ParagraphText
      css={{
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '16px',
        textAlign: 'left',
      }}
    >
      {label}
    </ParagraphText>
    <ParagraphText css={{ typography: '$subtitleTwo', textAlign: 'right' }}>
      {value}
    </ParagraphText>
  </StackLayout>
);
