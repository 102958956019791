import { useState, useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { CommunicationOption } from 'models/profile-settings.model';

interface ContentProps {
  labelId: string;
  value: string;
}

export const useCommunicationPreference = (
  initialOptions: CommunicationOption[],
) => {
  const { formatMessage } = useIntl();

  const formatValue = useCallback(
    (options: CommunicationOption[]): ContentProps[] => {
      const contents: ContentProps[] = [];
      const paperlessOption = options.find(
        (opt) => opt.labelId === 'STR_PAPERLESS',
      );
      if (paperlessOption) {
        contents.push({
          labelId: 'STR_PAPERLESS',
          value: paperlessOption.value
            ? formatMessage({ id: 'STR_YES' })
            : formatMessage({ id: 'STR_NO' }),
        });
      }

      const emailValues = options
        .filter((opt) =>
          ['STR_PERSONALIZED_EMAIL', 'STR_TIPS_AND_RECOMMENDATIONS'].includes(
            opt.labelId,
          ),
        )
        .map((opt) => {
          if (opt.labelId === 'STR_PERSONALIZED_EMAIL' && opt.value) {
            return formatMessage({ id: 'STR_EMAIL_PERSONALIZED_UPDATES' });
          }
          if (opt.labelId === 'STR_TIPS_AND_RECOMMENDATIONS' && opt.value) {
            return formatMessage({ id: 'STR_TIPS_AND_RECOMMENDATIONS' });
          }
          return '';
        })
        .filter(Boolean)
        .map((value) => `<div>${value}</div>`)
        .join('');

      if (emailValues) {
        contents.push({ labelId: 'STR_EMAIL_TITLE', value: emailValues });
      }

      options.forEach((option) => {
        if (
          ![
            'STR_PAPERLESS',
            'STR_PERSONALIZED_EMAIL',
            'STR_TIPS_AND_RECOMMENDATIONS',
          ].includes(option.labelId)
        ) {
          contents.push({
            labelId: option.labelId,
            value: option.value
              ? formatMessage({ id: 'STR_YES' })
              : formatMessage({ id: 'STR_NO' }),
          });
        }
      });

      return contents;
    },
    [formatMessage],
  );

  const [options, setOptions] = useState(initialOptions);
  const [contents, setContents] = useState<ContentProps[]>(
    formatValue(initialOptions),
  );
  const [showPaperlessModal, setShowPaperlessModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [pendingPaperless, setPendingPaperless] = useState<boolean | null>(
    null,
  );
  const [pendingUnsubscribe, setPendingUnsubscribe] = useState<boolean | null>(
    null,
  );

  const handleSave = useCallback(
    (editValues: Record<string, string>) => {
      const booleanValues = Object.fromEntries(
        Object.entries(editValues).map(([key, val]) => [key, val === 'true']),
      );

      const updatedOptions = options.map((option) => ({
        ...option,
        value: booleanValues[option.labelId] ?? option.value,
      }));
      setOptions(updatedOptions);
      setContents(formatValue(updatedOptions));
      setShowPaperlessModal(false);
      setShowUnsubscribeModal(false);
      setPendingPaperless(null);
      setPendingUnsubscribe(null);
    },
    [options, formatValue],
  );

  const handleInputChange = useCallback(
    (id: string, value: boolean) => {
      if (id === 'STR_PAPERLESS') {
        if (value) {
          setPendingPaperless(true);
          setShowPaperlessModal(true);
        } else {
          const updatedOptions = options.map((opt) =>
            opt.labelId === id ? { ...opt, value: false } : opt,
          );
          setOptions(updatedOptions);
          setContents(formatValue(updatedOptions));
        }
      } else if (id === 'STR_PERSONALIZED_EMAIL') {
        const hasPaperless = options.some(
          (opt) => opt.labelId === 'STR_PAPERLESS',
        );
        const isPaperlessYes = options.find(
          (opt) => opt.labelId === 'STR_PAPERLESS',
        )?.value;
        if (!value && hasPaperless && isPaperlessYes) {
          setPendingUnsubscribe(false);
          setShowUnsubscribeModal(true);
        } else {
          const updatedOptions = options.map((opt) =>
            opt.labelId === id ? { ...opt, value } : opt,
          );
          setOptions(updatedOptions);
          setContents(formatValue(updatedOptions));
        }
      } else {
        const updatedOptions = options.map((opt) =>
          opt.labelId === id ? { ...opt, value } : opt,
        );
        setOptions(updatedOptions);
        setContents(formatValue(updatedOptions));
      }
    },
    [options, formatValue],
  );

  const handlePaperlessConfirm = useCallback(() => {
    if (pendingPaperless !== null) {
      const updatedOptions = options.map((opt) => {
        switch (opt.labelId) {
          case 'STR_PAPERLESS':
            return { ...opt, value: pendingPaperless };
          case 'STR_PERSONALIZED_EMAIL':
            return { ...opt, value: true };
          default:
            return opt;
        }
      });
      setOptions(updatedOptions);
      setContents(formatValue(updatedOptions));
    }
    setShowPaperlessModal(false);
    setPendingPaperless(null);
  }, [pendingPaperless, options, formatValue]);

  const handlePaperlessCancel = useCallback(() => {
    setShowPaperlessModal(false);
    setPendingPaperless(null);
  }, []);

  const handleUnsubscribeConfirm = useCallback(() => {
    if (pendingUnsubscribe !== null) {
      const updatedOptions = options.map((opt) => {
        switch (opt.labelId) {
          case 'STR_PERSONALIZED_EMAIL':
            return { ...opt, value: pendingUnsubscribe };
          case 'STR_PAPERLESS':
            return { ...opt, value: false };
          default:
            return opt;
        }
      });
      setOptions(updatedOptions);
      setContents(formatValue(updatedOptions));
    }
    setShowUnsubscribeModal(false);
    setPendingUnsubscribe(null);
  }, [pendingUnsubscribe, options, formatValue]);

  const handleUnsubscribeCancel = useCallback(() => {
    setShowUnsubscribeModal(false);
    setPendingUnsubscribe(null);
  }, []);

  return {
    contents,
    handleSave,
    handleInputChange,
    showPaperlessModal,
    setShowPaperlessModal,
    showUnsubscribeModal,
    setShowUnsubscribeModal,
    handlePaperlessConfirm,
    handlePaperlessCancel,
    handleUnsubscribeConfirm,
    handleUnsubscribeCancel,
    currentOptions: options,
  };
};
