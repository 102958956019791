// Onboarding asset overrides
import onboardingonboardingScreenBg from 'assets/cigna-feature-background.svg';
import journeyHeaderBackground from 'assets/journey-header-background.svg';
import journeyHeaderEmptyStateHistory from 'assets/journey-header-empty-state-history.svg';

const JOURNEY_ASSETS = {
  JOURNEY_HEADER_BACKGROUND_IMAGE: journeyHeaderBackground,
  EMPTY_STATE_HISTORY: journeyHeaderEmptyStateHistory,
};

const ONBOARDING_ASSETS = {
  FEATURE_HIGHLIGHT_BACKGROUND: onboardingonboardingScreenBg,
};

export const ASSETS_CONFIG = {
  ...JOURNEY_ASSETS,
  ...ONBOARDING_ASSETS,
};
