import { useMemo } from 'react';
import {
  NavLinkInterface,
  baseNavLinks,
  claimsNavLink,
} from 'models/nav-config.model';

export const useNavLinksList = (showClaims: boolean) => {
  const navLinks = useMemo<NavLinkInterface[]>(
    () =>
      showClaims
        ? [...baseNavLinks.slice(0, 3), claimsNavLink, baseNavLinks[3]]
        : baseNavLinks,
    [showClaims],
  );

  return { navLinks };
};
