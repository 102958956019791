import React from 'react';
import {
  StackLayout,
  ParagraphText,
  Checkbox,
  Radio,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface CommunicationEditFormProps {
  editValues: Record<string, boolean>;
  showPaperlessSetup: boolean;
  onInputChange: (id: string, value: boolean) => void;
}

export const CommunicationEditForm: React.FC<CommunicationEditFormProps> = ({
  editValues,
  showPaperlessSetup,
  onInputChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      spacing="$oneAndHalf"
      css={{ width: '100%', strong: { fontWeight: 700 } }}
    >
      {showPaperlessSetup && (
        <StackLayout>
          <HeadingText level="4" size="sm">
            {formatMessage({ id: 'STR_PAPERLESS_SETUP_TITLE' })}
          </HeadingText>
          <ParagraphText
            css={{
              marginTop: '$one',
              marginBottom: '$half',
              typography: '$bodyTwo',
            }}
          >
            {formatMessage({ id: 'STR_PAPERLESS_SETUP_DESCRIPTION' })}
          </ParagraphText>
          <StackLayout
            orientation="vertical"
            spacing="$one"
            css={{ marginTop: '$one' }}
          >
            <Radio
              id="radio-paperless-yes"
              name="paperless"
              value="yes"
              label={formatMessage({ id: 'STR_YES_PAPERLESS' })}
              checked={editValues.STR_PAPERLESS === true}
              onChange={() => onInputChange('STR_PAPERLESS', true)}
            />
            <Radio
              id="radio-paperless-no"
              name="paperless"
              value="no"
              label={formatMessage({ id: 'STR_NO_PAPERLESS' })}
              checked={editValues.STR_PAPERLESS === false}
              onChange={() => onInputChange('STR_PAPERLESS', false)}
            />
          </StackLayout>
        </StackLayout>
      )}

      <StackLayout>
        <HeadingText level="4" size="sm" css={{ typography: '$subtitleOne' }}>
          {formatMessage({ id: 'STR_EMAIL_TITLE' })}
        </HeadingText>
        <ParagraphText css={{ marginTop: '$half', typography: '$bodyOne' }}>
          {formatMessage({ id: 'STR_EMAIL_DESCRIPTION' })}
        </ParagraphText>

        <HeadingText
          level="4"
          size="sm"
          css={{ typography: '$subtitleTwo', marginTop: '$one' }}
        >
          {formatMessage({ id: 'STR_PERSONALIZED_EMAIL' })}
        </HeadingText>
        <Checkbox
          label={formatMessage({ id: 'STR_PERSONALIZED_EMAIL_DESCRIPTION' })}
          id="checkbox-personalized-email"
          checked={editValues.STR_PERSONALIZED_EMAIL}
          onChange={(e) =>
            onInputChange('STR_PERSONALIZED_EMAIL', e.target.checked)
          }
          name=""
        />
        <ParagraphText
          css={{
            marginTop: '$one',
            marginBottom: '$one',
            typography: '$bodyTwo',
          }}
        >
          {formatMessage({ id: 'STR_PERSONALIZED_EMAIL_HINT' })}
        </ParagraphText>

        <HeadingText
          level="4"
          size="sm"
          css={{ typography: '$subtitleTwo', marginTop: '$one' }}
        >
          {formatMessage({ id: 'STR_TIPS_AND_RECOMMENDATIONS' })}
        </HeadingText>
        <Checkbox
          label={formatMessage({ id: 'STR_TIPS_RECOMMENDATIONS' })}
          id="checkbox-tips-recommendations"
          checked={editValues.STR_TIPS_AND_RECOMMENDATIONS}
          onChange={(e) =>
            onInputChange('STR_TIPS_AND_RECOMMENDATIONS', e.target.checked)
          }
          name=""
        />
      </StackLayout>

      <StackLayout>
        <HeadingText level="4" size="sm">
          {formatMessage({ id: 'STR_PRODUCT_UPDATES' })}
        </HeadingText>
        <Checkbox
          label={formatMessage({ id: 'STR_PRODUCT_UPDATES_DESCRIPTION' })}
          id="checkbox-product-updates"
          checked={editValues.STR_PRODUCT_UPDATES}
          onChange={(e) =>
            onInputChange('STR_PRODUCT_UPDATES', e.target.checked)
          }
          name=""
        />
      </StackLayout>

      <StackLayout>
        <HeadingText level="4" size="sm">
          {formatMessage({ id: 'STR_TEXT_MESSAGE_PREFERENCES' })}
        </HeadingText>
        <Checkbox
          label={formatMessage({ id: 'STR_TEXT_MESSAGE_DESCRIPTION' })}
          id="checkbox-text-message"
          checked={editValues.STR_TEXT}
          onChange={(e) => onInputChange('STR_TEXT', e.target.checked)}
          name=""
        />
        <ParagraphText css={{ typography: '$bodyTwo', marginTop: '$one' }}>
          {formatMessage({ id: 'STR_TEXT_MESSAGE_HINT' })}
        </ParagraphText>
      </StackLayout>

      <StackLayout>
        <HeadingText level="4" size="sm">
          {formatMessage({ id: 'STR_LANGUAGE' })}
        </HeadingText>
        <Checkbox
          label={formatMessage({ id: 'STR_LANGUAGE_DESCRIPTION' })}
          id="checkbox-language-preferences"
          checked={editValues.STR_LANGUAGE}
          onChange={(e) => onInputChange('STR_LANGUAGE', e.target.checked)}
          name=""
        />
      </StackLayout>
    </StackLayout>
  );
};
