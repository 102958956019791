import React, { useCallback, useMemo, useState } from 'react';
import {
  StackLayout,
  HeadingText,
  ParagraphText,
  Button,
  useMediaQuery,
  queryHelpers,
  Modal,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { captureError } from '@leagueplatform/observability';
import { ContentProps } from 'models/profile-settings.model';
import { UnsaveChangesWarningModal } from 'components/modals/unsave-changes-warning-modal.component';
import { DetailItem } from './details-item.component';

interface DetailSectionProps {
  title: string;
  contents: ContentProps[];
  ssoUrl?: string;
  onSave?: (values: Record<string, string>) => Promise<void> | void;
  renderEditContent?: (props: {
    contents: ContentProps[];
    editValues: Record<string, string>;
    onChange: (id: string, value: string) => void;
    isMobile: boolean;
  }) => React.ReactNode;
  modals?: React.ReactNode;
  formatValue?: (value: string, labelId: string) => string;
}

export const DetailSection: React.FC<DetailSectionProps> = ({
  title,
  contents,
  ssoUrl,
  onSave,
  renderEditContent,
  modals,
  formatValue = (value) => value,
}) => {
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));
  const { formatMessage } = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);

  const initialValues = useMemo(
    () =>
      Object.fromEntries(contents.map((item) => [item.labelId, item.value])),
    [contents],
  );
  const [editValues, setEditValues] =
    useState<Record<string, string>>(initialValues);

  const t = useCallback(
    (id: string) => formatMessage({ id: id as never }),
    [formatMessage],
  );

  const hasUnsavedChanges = useMemo(
    () =>
      Object.keys(editValues).some(
        (key) => editValues[key] !== initialValues[key],
      ),
    [editValues, initialValues],
  );

  const handleInputChange = useCallback((id: string, value: string) => {
    setEditValues((prev) => ({ ...prev, [id]: value }));
  }, []);

  const handleSave = useCallback(async () => {
    if (!onSave) return;
    try {
      await onSave(editValues);
      setIsEditing(false);
    } catch (error) {
      captureError(new Error(`Failed to save profile settings: ${error}`));
    }
  }, [editValues, onSave]);

  const handleCancel = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowUnsavedModal(true);
    } else {
      setEditValues(initialValues);
      setIsEditing(false);
    }
  }, [hasUnsavedChanges, initialValues]);

  const handleUnsavedConfirm = useCallback(() => {
    setEditValues(initialValues);
    setIsEditing(false);
    setShowUnsavedModal(false);
  }, [initialValues]);

  const handleUnsavedSave = () => setShowUnsavedModal(false);

  const renderedContent = useMemo(() => {
    if (!isEditing || !renderEditContent) {
      return contents.length > 0 ? (
        contents.map((content, index) => (
          <DetailItem
            key={content.labelId}
            label={t(content.labelId)}
            value={formatValue(content.value, content.labelId)}
            isLastItem={index === contents.length - 1}
          />
        ))
      ) : (
        <ParagraphText>{t('STR_NO_CONTENT')}</ParagraphText>
      );
    }
    return renderEditContent({
      contents,
      editValues,
      onChange: handleInputChange,
      isMobile,
    });
  }, [
    isEditing,
    contents,
    editValues,
    handleInputChange,
    isMobile,
    renderEditContent,
    formatValue,
    t,
  ]);

  return (
    <StackLayout
      css={{
        width: '100%',
        margin: '$half auto',
        border: '1px solid $onSurfaceBorderSubdued',
        borderRadius: '$card',
        overflow: 'hidden',
        padding: '$two $oneAndHalf',
      }}
    >
      <StackLayout
        orientation="horizontal"
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '$half',
          width: '100%',
        }}
      >
        <HeadingText level="3" size="md" css={{ fontSize: '20px' }}>
          {t(title)}
        </HeadingText>
        {!isEditing && (
          <Button
            icon="interfaceEdit"
            as={ssoUrl ? 'a' : undefined}
            href={ssoUrl || undefined}
            onClick={() => {
              if (!ssoUrl) {
                setIsEditing(true);
              }
            }}
            priority="secondary"
            css={{
              height: '$two',
              paddingX: '12px',
              fontWeight: '500',
            }}
          >
            {t('STR_EDIT')}
          </Button>
        )}
      </StackLayout>

      {renderedContent}

      {isEditing && (
        <StackLayout
          orientation="horizontal"
          css={{
            justifyContent: 'flex-end',
            gap: '$one',
            marginTop: '$oneAndHalf',
            width: '100%',
          }}
        >
          <Button
            onClick={handleCancel}
            priority="secondary"
            css={{
              height: '$three',
            }}
          >
            {t('STR_CANCEL')}
          </Button>
          <Button
            onClick={handleSave}
            css={{
              height: '$three',
            }}
          >
            {t('STR_SAVE_CHANGES')}
          </Button>
        </StackLayout>
      )}

      <Modal.Root open={showUnsavedModal} onOpenChange={handleUnsavedSave}>
        <UnsaveChangesWarningModal
          onConfirm={handleUnsavedConfirm}
          onClose={handleUnsavedSave}
        />
      </Modal.Root>

      {modals}
    </StackLayout>
  );
};
