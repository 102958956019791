import React, { useCallback, useState } from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { ContentProps } from 'models/profile-settings.model';
import { EditableDetailItem } from './editable-detail-item.component';
import { DetailSection } from './detail-section.component';

interface PersonalInfoProps {
  firstName: string;
  lastName: string;
  preferredFirstName: string;
}

export const PersonalInfoComponent: React.FC<PersonalInfoProps> = ({
  lastName,
  firstName,
  preferredFirstName,
}) => {
  const [contents, setContents] = useState<ContentProps[]>([
    {
      labelId: 'STR_FIRST_NAME',
      value: firstName,
      type: 'text',
      isDisabled: true,
    },
    {
      labelId: 'STR_LAST_NAME',
      value: lastName,
      type: 'text',
      isDisabled: true,
    },
    {
      labelId: 'STR_PREFERRED_FIRST_NAME',
      value: preferredFirstName,
      type: 'text',
    },
  ]);

  const handleSave = useCallback(
    (editValues: Record<string, string>) => {
      const updatedContents = contents.map((content) => ({
        ...content,
        value: editValues[content.labelId] ?? content.value,
      }));

      setContents(updatedContents);
    },
    [contents],
  );

  return (
    <DetailSection
      title="STR_PERSONAL_INFORMATION"
      contents={contents}
      onSave={handleSave}
      renderEditContent={({
        contents: editContents,
        editValues,
        onChange,
        isMobile,
      }) => (
        <StackLayout
          orientation="horizontal"
          css={{
            display: 'grid',
            gridTemplateColumns: isMobile
              ? '1fr'
              : 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: isMobile ? '$two' : '$one',
            width: '100%',
          }}
        >
          {editContents.map((content) => (
            <EditableDetailItem
              key={content.labelId}
              id={content.labelId}
              labelId={content.labelId}
              value={editValues[content.labelId]}
              type={content.type!}
              isDisabled={content.isDisabled}
              options={content.options}
              onChange={onChange}
            />
          ))}
        </StackLayout>
      )}
    />
  );
};
