import { FC } from 'react';
import { Address } from 'models/profile-settings.model';
import { useProfileSettingsSegmentation } from 'hooks/use-profile-settings-segmentation.hook';
import { useAddressForm } from 'hooks/use-address-form.hook';
import { AddressEditSection } from './address-edit-section.component';
import { DetailSection } from '../detail-section.component';
import { ReadOnlyDetailsSection } from '../read-only-details-section.component';

interface AddressInformationProps {
  mailingAddress: Address;
  residentialAddress: Address;
}

export const AddressInformationComponent: FC<AddressInformationProps> = ({
  mailingAddress: initialMailingAddress,
  residentialAddress: initialResidentialAddress,
}) => {
  const { isAddressEditable } = useProfileSettingsSegmentation();
  const { addresses, contents, handleSave } = useAddressForm(
    initialMailingAddress,
    initialResidentialAddress,
  );

  const renderEditContent = ({
    editValues,
    onChange,
    isMobile,
  }: {
    editValues: Record<string, string>;
    onChange: (id: string, value: string) => void;
    isMobile: boolean;
  }) =>
    addresses.map((address) => (
      <AddressEditSection
        key={address.type}
        address={address}
        editValues={editValues}
        onChange={onChange}
        isMobile={isMobile}
      />
    ));

  return isAddressEditable ? (
    <DetailSection
      title="STR_ADDRESS_INFORMATION"
      contents={contents}
      onSave={handleSave}
      renderEditContent={renderEditContent}
    />
  ) : (
    <ReadOnlyDetailsSection
      title="STR_ADDRESS_INFORMATION"
      description="STR_PROFILE_SETTINGS_ADDRESS_DESCRIPTION"
    />
  );
};
