import { useState } from 'react';
import { Address, AddressContent } from 'models/profile-settings.model';
import { flattenAddressContents } from 'utils/address.utils';
import { US_STATES } from 'common/constants';

export const useAddressForm = (
  initialMailingAddress: Address,
  initialResidentialAddress: Address,
) => {
  const contentsFromAddress = (
    address: Address,
  ): AddressContent['contents'] => [
    {
      labelId: 'STR_STREET_ADDRESS',
      value: address?.address1 || '',
      type: 'text',
    },
    {
      labelId: 'STR_APT_SUITE_UNIT_FLOOR',
      value: address.address2 || '',
      type: 'text',
    },
    { labelId: 'STR_CITY', value: address?.city || '', type: 'text' },
    {
      labelId: 'STR_STATE',
      value: address?.state || '',
      type: 'select',
      options: US_STATES,
    },
    { labelId: 'STR_POSTAL_CODE', value: address?.postal || '', type: 'text' },
    { labelId: 'STR_COUNTRY', value: address?.country || '', type: 'text' },
  ];

  const [mailingAddress, setMailingAddress] = useState<Address>(
    initialMailingAddress,
  );
  const [residentialAddress, setResidentialAddress] = useState<Address>(
    initialResidentialAddress,
  );

  const addresses: AddressContent[] = [
    {
      type: 'mailing',
      title: 'STR_MAILING_ADDRESS',
      contents: contentsFromAddress(mailingAddress),
    },
    {
      type: 'home',
      title: 'STR_RESIDENTIAL_ADDRESS',
      contents: contentsFromAddress(residentialAddress),
    },
  ];

  const contents = flattenAddressContents(addresses);

  const transformEditValues = (editValues: Record<string, string>) => {
    const updatedMailing: Address = {
      address1:
        editValues.STR_STREET_ADDRESS_mailing || mailingAddress.address1,
      address2:
        editValues.STR_APT_SUITE_UNIT_FLOOR_mailing ||
        mailingAddress.address2 ||
        '',
      city: editValues.STR_CITY_mailing || mailingAddress.city,
      state: editValues.STR_STATE_mailing || mailingAddress.state,
      postal: editValues.STR_POSTAL_CODE_mailing || mailingAddress.postal,
      country: editValues.STR_COUNTRY_mailing || mailingAddress.country,
    };

    const updatedResidential: Address = {
      address1:
        editValues.STR_STREET_ADDRESS_home || residentialAddress.address1,
      address2:
        editValues.STR_APT_SUITE_UNIT_FLOOR_home ||
        residentialAddress.address2 ||
        '',
      city: editValues.STR_CITY_home || residentialAddress.city,
      state: editValues.STR_STATE_home || residentialAddress.state,
      postal: editValues.STR_POSTAL_CODE_home || residentialAddress.postal,
      country: editValues.STR_COUNTRY_home || residentialAddress.country,
    };

    setMailingAddress(updatedMailing);
    setResidentialAddress(updatedResidential);
  };

  const handleSave = (editValues: Record<string, string>) => {
    transformEditValues(editValues);
  };

  return { addresses, contents, handleSave };
};
