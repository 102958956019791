import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useConditionalNavigation = () => {
  const history = useHistory();

  const clickHandler = useCallback(
    (event) => {
      const anchor = event.target.closest('a');
      if (anchor) {
        const href = anchor.getAttribute('href');
        if (href && href.startsWith('/health-journey')) {
          event.preventDefault();
          const newHref = href.replace('/health-journey', '/messages');
          history.push(newHref);
        }
      }
    },
    [history],
  );

  useEffect(() => {
    document.addEventListener('click', clickHandler);
    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [clickHandler]);
};
